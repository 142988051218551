import { Injectable } from '@angular/core';
declare var PolygonIdSdk: any;
const { AgentResolver, AuthHandler, AuthorizationRequestMessage, AuthorizationResponseMessage, BjjProvider, CircuitId, CircuitStorage, CredentialRequest, CredentialStatus, CredentialStatusResolverRegistry, CredentialStatusType, CredentialStorage, CredentialWallet, EthStateStorage, IProofService, IStateStorage, Iden3ProofCreationResult, IdentityStorage, IdentityWallet, InMemoryDataSource, InMemoryMerkleTreeStorage, InMemoryPrivateKeyStore, IssuerResolver, KMS, KmsKeyType, OnChainResolver, PROTOCOL_CONSTANTS, ProofService, RHSResolver, W3CCredential, ZeroKnowledgeProofRequest, byteEncoder, core, defaultEthConnectionConfig } = PolygonIdSdk;
import axios from 'axios';
import { DataStorage, initPackageManager } from "../utills/did-utils";

@Injectable({
    providedIn: 'root'
})
export class DidVcService {

    private dataStorage!: DataStorage;
    private resolvers!: typeof CredentialStatusResolverRegistry;
    private credWallet!: typeof CredentialWallet;
    private wallet!: typeof IdentityWallet;
    private rhsUrl!: string;
    private proofService!: typeof IProofService;
    private circuitStorage!: typeof CircuitStorage;
    private circuitsURL!: string;
    private initialized: boolean = false;

    private async ensureInitialized(): Promise<void> {
        if (!this.initialized) {
            await this.init('https://rpc-mumbai.maticvigil.com', '0x134b1be34911e39a8397ec6289782989729807a4', 'https://ipfs.io/ipfs/QmTJeH3wXrNhsuerWn8oxxcbn9aR39RWgH5mcsXWtaSU8t/');
            // throw new Error('SSDID Service is not initialized. Please call init() first.');
        }
    }


    async initProofService() {
        try {
            this.circuitStorage = new CircuitStorage(new InMemoryDataSource());
            const loadFile = async (path: string): Promise<Uint8Array> => {
                try {
                    const url = `${this.circuitsURL}${path}`;
                    const response = await axios.get(url, { responseType: 'arraybuffer' })
                    const arrayBuffer = response.data;
                    if (!arrayBuffer) {
                        throw new Error("ArrayBuffer is undefined");
                    }

                    return new Uint8Array(arrayBuffer);
                } catch (error) {
                    throw error;
                }
            };
            await this.circuitStorage.saveCircuitData(CircuitId.AtomicQuerySigV2, {
                circuitId: CircuitId.AtomicQuerySigV2,
                wasm: await loadFile(`${CircuitId.AtomicQuerySigV2.toString()}/circuit.wasm`),
                provingKey: await loadFile(`${CircuitId.AtomicQuerySigV2.toString()}/circuit_final.zkey`),
                verificationKey: await loadFile(
                    `${CircuitId.AtomicQuerySigV2.toString()}/verification_key.json`
                )
            });

            await this.circuitStorage.saveCircuitData(CircuitId.StateTransition, {
                circuitId: CircuitId.StateTransition,
                wasm: await loadFile(`${CircuitId.StateTransition.toString()}/circuit.wasm`),
                provingKey: await loadFile(`${CircuitId.StateTransition.toString()}/circuit_final.zkey`),
                verificationKey: await loadFile(
                    `${CircuitId.StateTransition.toString()}/verification_key.json`
                )
            });

            await this.circuitStorage.saveCircuitData(CircuitId.AtomicQueryMTPV2, {
                circuitId: CircuitId.AtomicQueryMTPV2,
                wasm: await loadFile(`${CircuitId.AtomicQueryMTPV2.toString()}/circuit.wasm`),
                provingKey: await loadFile(`${CircuitId.AtomicQueryMTPV2.toString()}/circuit_final.zkey`),
                verificationKey: await loadFile(
                    `${CircuitId.AtomicQueryMTPV2.toString()}/verification_key.json`
                )
            });
            await this.circuitStorage.saveCircuitData(CircuitId.AuthV2, {
                circuitId: CircuitId.AuthV2,
                wasm: await loadFile(`${CircuitId.AuthV2.toString()}/circuit.wasm`),
                provingKey: await loadFile(`${CircuitId.AuthV2.toString()}/circuit_final.zkey`),
                verificationKey: await loadFile(
                    `${CircuitId.AuthV2.toString()}/verification_key.json`
                )
            });
            this.proofService = new ProofService(this.wallet, this.credWallet, this.circuitStorage, this.dataStorage.states);
            this.initialized = true;
        } catch (error) {
            throw error;
        }
    }

    async init(url: string, contractAddress: string, cicuitUrl: string, rhsUrl?: string) {
        try {
            if (this.initialized) {
                return;
            }
            this.rhsUrl = rhsUrl ?? 'https://rhs-staging.polygonid.me';
            this.circuitsURL = cicuitUrl;
            const conf = defaultEthConnectionConfig;
            conf.url = url;
            conf.contractAddress = contractAddress;
            this.dataStorage = {
                credential: new CredentialStorage(new InMemoryDataSource()),
                identity: new IdentityStorage(
                    new InMemoryDataSource(),
                    new InMemoryDataSource()
                ),
                mt: new InMemoryMerkleTreeStorage(40),
                states: new EthStateStorage(conf),
            };

            const memoryKeyStore = new InMemoryPrivateKeyStore();
            const bjjProvider = new BjjProvider(KmsKeyType.BabyJubJub, memoryKeyStore);
            const kms = new KMS();
            kms.registerKeyProvider(KmsKeyType.BabyJubJub, bjjProvider);
            this.resolvers = new CredentialStatusResolverRegistry();
            this.resolvers.register(
                CredentialStatusType.SparseMerkleTreeProof,
                new IssuerResolver()
            );
            this.resolvers.register(
                CredentialStatusType.Iden3ReverseSparseMerkleTreeProof,
                new RHSResolver(this.dataStorage.states)
            );
            this.resolvers.register(
                CredentialStatusType.Iden3OnchainSparseMerkleTreeProof2023,
                new OnChainResolver([defaultEthConnectionConfig])
            );
            this.resolvers.register(
                CredentialStatusType.Iden3commRevocationStatusV1,
                new AgentResolver()
            );
            this.credWallet = new CredentialWallet(this.dataStorage, this.resolvers);
            this.wallet = new IdentityWallet(kms, this.dataStorage, this.credWallet);
            await this.initProofService();

        } catch (error) {
            throw error;
        }
    }

    random32characterGenerator() {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 32; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    }

    async createIdentity(seed?: string): Promise<{ did: typeof core.DID, credential: typeof W3CCredential }> {
        this.ensureInitialized();
        try {
            if (!seed) {
                seed = this.random32characterGenerator();
            }
            const seedPhrase: Uint8Array = byteEncoder.encode(seed);
            const { did, credential } = await this.wallet.createIdentity({
                method: core.DidMethod.Iden3,
                blockchain: core.Blockchain.Polygon,
                networkId: core.NetworkId.Mumbai,
                seed: seedPhrase,
                revocationOpts: {
                    type: CredentialStatusType.Iden3ReverseSparseMerkleTreeProof,
                    id: this.rhsUrl,
                }
            });
            return { did, credential };
        } catch (error) {
            throw error;
        }
    }

    async saveCredentials(credentials: typeof W3CCredential[]): Promise<boolean> {
        this.ensureInitialized();
        try {
            await this.credWallet.saveAll(credentials);
            return true;
        } catch (error) {
            throw error;
        }
    }


    // moving expiration to expirationDate 
    async issueCredential(
        issuerDID: typeof core.DID,
        credentialSchema: string,
        credentialType: string,
        credentialSubject: any,
        expirationDate?: number,
    ): Promise<{ credential: typeof W3CCredential }> {
        this.ensureInitialized();
        try {
            const claimReq: typeof CredentialRequest = {
                credentialSchema,
                type: credentialType,
                credentialSubject,
                expirationDate,
                revocationOpts: {
                    nonce: 1000,
                    type: CredentialStatusType.Iden3ReverseSparseMerkleTreeProof,
                    id: this.rhsUrl
                }
            };
            const issuerCred = await this.wallet.issueCredential(issuerDID, claimReq);
            await this.credWallet.save(issuerCred);
            return {
                credential: issuerCred,
            }
        } catch (error) {
            throw error;
        }
    }

    async createProofReq() {
        const proofReq: typeof ZeroKnowledgeProofRequest = {
            id: 1,
            circuitId: CircuitId.AtomicQuerySigV2,
            optional: false,
            query: {
                allowedIssuers: ['*'],
                type: 'KYCAgeCredential',
                context:
                    'https://raw.githubusercontent.com/iden3/claim-schema-vocab/main/schemas/json-ld/kyc-v3.json-ld',
                credentialSubject: {
                    documentType: {
                        $eq: 99
                    }
                }
            }
        };
        return proofReq;
    }

    async createAuthReq(userID, proofReq) {
        const authRequest: typeof AuthorizationRequestMessage = {
            id: "fe6354fe-3db2-48c2-a779-e39c2dda8d90",
            thid: "fe6354fe-3db2-48c2-a779-e39c2dda8d90",
            typ: PROTOCOL_CONSTANTS.MediaType.PlainMessage,
            from: userID.did.id.toString(),
            type: PROTOCOL_CONSTANTS.PROTOCOL_MESSAGE_TYPE
                .AUTHORIZATION_REQUEST_MESSAGE_TYPE,
            body: {
                callbackUrl: "http://testcallback.com",
                message: "message to sign",
                scope: [proofReq],
                reason: "verify age",
            },
        };
        return authRequest;
    }

    async generateProof(fromDID: typeof core.DID, authRequest: typeof AuthorizationRequestMessage, proofReq: typeof ZeroKnowledgeProofRequest): Promise<{
        token: string;
        authRequest: typeof AuthorizationRequestMessage;
        authResponse: typeof AuthorizationResponseMessage;
    }> {
        this.ensureInitialized();
        try {
            const credsFromWallet = await this.credWallet.findByQuery(proofReq.query);
            if (credsFromWallet.length == 0) {
                throw new Error("No matching credential found");
            }
            const authRawRequest = new TextEncoder().encode(JSON.stringify(authRequest));
            const authV2Data = await this.circuitStorage.loadCircuitData(CircuitId.AuthV2);
            let pm = await initPackageManager(
                authV2Data,
                this.proofService.generateAuthV2Inputs.bind(this.proofService),
                this.proofService.verifyState.bind(this.proofService)
            );
            const authHandler = new AuthHandler(pm, this.proofService);
            const authHandlerRequest = await authHandler.handleAuthorizationRequest(
                fromDID,
                authRawRequest
            );
            return authHandlerRequest;
        } catch (error) {
            throw error;
        }
    }
}
