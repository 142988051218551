declare var PolygonIdSdk: any;
declare var JWZ: any;
const { CredentialStorage, EthStateStorage, IdentityStorage, InMemoryMerkleTreeStorage, AuthDataPrepareFunc, CircuitData, DataPrepareHandlerFunc, IPackageManager, PackageManager, PlainPacker, ProvingParams, StateVerificationFunc, VerificationHandlerFunc, VerificationParams, ZKPPacker } = PolygonIdSdk;
const { proving } = JWZ;


export type DataStorage = {
  credential: typeof CredentialStorage;
  identity: typeof IdentityStorage;
  mt: typeof InMemoryMerkleTreeStorage;
  states: typeof EthStateStorage;
};

export async function initPackageManager(
  circuitData: typeof CircuitData,
  prepareFn: typeof AuthDataPrepareFunc,
  stateVerificationFn: typeof StateVerificationFunc
): Promise<typeof IPackageManager> {
  const authInputsHandler = new DataPrepareHandlerFunc(prepareFn);

  const verificationFn = new VerificationHandlerFunc(stateVerificationFn);
  const mapKey =
    proving.provingMethodGroth16AuthV2Instance.methodAlg.toString();
  const verificationParamMap: Map<string, typeof VerificationParams> = new Map([
    [
      mapKey,
      {
        key: circuitData.verificationKey!,
        verificationFn,
      },
    ],
  ]);

  const provingParamMap: Map<string, typeof ProvingParams> = new Map();
  provingParamMap.set(mapKey, {
    dataPreparer: authInputsHandler,
    provingKey: circuitData.provingKey!,
    wasm: circuitData.wasm!,
  });

  const mgr: typeof IPackageManager = new PackageManager();
  const packer = new ZKPPacker(provingParamMap, verificationParamMap);
  const plainPacker = new PlainPacker();
  mgr.registerPackers([packer, plainPacker]);

  return mgr;
}


const loadExistingCredentials = async () => {
  try {
    const data = '{}'; // Return an empty object to simulate loading from a JSON file
    return JSON.parse(data);
  } catch (error) {
    // If an error occurs, return an empty object
    return {
      "@context": []
    };
  }
}
let existingCredentials = loadExistingCredentials(); // Load existing credentials (empty object in this case)
export async function generateCredential(credentialName, fields) {
  const parameters = {};
  const properties = {};
  for (const field of fields) {
    const fieldId = `kyc-vocab:${field.name}`;
    const fieldType = `xsd:${field.type}`;
    parameters[field.name] = {
      "@id": fieldId,
      "@type": fieldType,
    };
    properties[field.name] = {
      "type": field.type,
    };
  }
  return createKYCCredential(credentialName, parameters, properties);
}
const createKYCCredential = (credentialName, parameters, properties) => {
  const newCredentialContext = {
    [credentialName]: {
      "@id": `https://example.com/your-schema#${credentialName}`,
      "@context": {
        "@version": 1.1,
        "@protected": true,
        "id": "@id",
        "type": "@type",
        "kyc-vocab": "https://github.com/iden3/claim-schema-vocab/blob/main/credentials/kyc.md#",
        "xsd": "http://www.w3.org/2001/XMLSchema#",
        ...parameters
      }
    }
  };
  if (!Array.isArray(existingCredentials["@context"])) {
    existingCredentials["@context"] = [];
  }
  existingCredentials["@context"].push(newCredentialContext);
  const baseCredentialSchema = {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "type": "object",
    "$metadata": {
      "uris": {
        "jsonLdContext": "https://raw.githubusercontent.com/iden3/claim-schema-vocab/main/schemas/json-ld/kyc-v3.json-ld",
        "jsonSchema": `https://raw.githubusercontent.com/iden3/claim-schema-vocab/main/schemas/json/${credentialName}.json`
      }
    },
    "required": [
      "@context",
      "id",
      "type",
      "issuanceDate",
      "credentialSubject",
      "credentialSchema",
      "credentialStatus",
      "issuer"
    ],
    "properties": {
      "@context": {
        "type": ["string", "array", "object"]
      },
      "id": {
        "type": "string"
      },
      "type": {
        "type": ["string", "array"],
        "items": {
          "type": "string"
        }
      },
      "issuer": {
        "type": ["string", "object"],
        "format": "uri",
        "required": ["id"],
        "properties": {
          "id": {
            "type": "string",
            "format": "uri"
          }
        }
      },
      "issuanceDate": {
        "type": "string",
        "format": "date-time"
      },
      "expirationDate": {
        "type": "string",
        "format": "date-time"
      },
      "credentialSchema": {
        "type": "object",
        "required": ["id", "type"],
        "properties": {
          "id": {
            "type": "string",
            "format": "uri"
          },
          "type": {
            "type": "string"
          }
        }
      },
      "subjectPosition": {
        "type": "string",
        "enum": ["none", "index", "value"]
      },
      "merklizedRootPosition": {
        "type": "string",
        "enum": ["none", "index", "value"]
      },
      "revNonce": {
        "type": "integer"
      },
      "version": {
        "type": "integer"
      },
      "updatable": {
        "type": "boolean"
      },
      "credentialSubject": {
        "type": "object",
        "required": ["id", ...Object.keys(properties)],
        "properties": {
          "id": {
            "title": "Credential Subject ID",
            "type": "string",
            "format": "uri"
          },
          ...properties
        }
      }
    }
  };

  return { existingCredentials, baseCredentialSchema, data: JSON.stringify(baseCredentialSchema, null, 2) };
};
const credentialName = 'KYCBirthdayCredential';
const fields = [
  { name: 'birthday', type: 'integer' },
  { name: 'age', type: 'integer' },
  { name: 'documentType', type: 'integer' }
];
const result = generateCredential(credentialName, fields);
//console.log('Generated Credential:', result);

export declare enum Blockchain {
  Ethereum = "eth",
  Polygon = "polygon",
  ZkEVM = "zkevm",
  Unknown = "unknown",
  NoChain = "",
  ReadOnly = "readonly",
  Volary = "vlry"
}
export declare enum NetworkId {
  Main = "main",
  Mumbai = "mumbai",
  Goerli = "goerli",
  Sepolia = "sepolia",
  Test = "test",
  Unknown = "unknown",
  NoNetwork = ""
}