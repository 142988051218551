import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DidVcService } from './core/services/did-vc.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit
{
    /**
     * Constructor
     */
    constructor(
        private _did: DidVcService
    ){}

    async ngOnInit() {
        // await this._did.init('https://rpc-mumbai.maticvigil.com', '0x134b1be34911e39a8397ec6289782989729807a4', 'https://ipfs.io/ipfs/QmTJeH3wXrNhsuerWn8oxxcbn9aR39RWgH5mcsXWtaSU8t/');
    }
}
