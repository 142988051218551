import { createStore, select, withProps } from '@ngneat/elf';
import {
    persistState,
    localStorageStrategy,
  } from '@ngneat/elf-persist-state';
import { User } from 'app/core/user/user.types';

export const userStore = createStore(
    { name: 'user' },
    withProps<User>({ id: null, name: null, email: null, avatar: null, status: null})
);

export const persist = persistState(userStore, {
    key: 'user',
    storage: localStorageStrategy,
  });

export class UserRepository {
    user$ = userStore.pipe(select((state) => state));

    getUser() {
      return userStore.getValue();
    }
  
    updateUser(user: User) {
      const userObj = {
        id: user['_id'],
        ...user
    }
        userStore.update((state) => ({
        ...state,
        ...userObj,
      }));
    }
  }