/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'explore',
        title: 'Explore',
        type : 'basic',
        icon : 'heroicons_outline:globe-alt',
        link : '/hackathons'
    },
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'explore',
        title: 'Explore',
        type : 'basic',
        icon : 'heroicons_outline:globe-alt',
        link : '/hackathons'
    },
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'explore',
        title: 'Explore',
        type : 'basic',
        icon : 'heroicons_outline:globe-alt',
        link : '/hackathons'
    },
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'explore',
        title: 'Explore',
        type : 'basic',
        icon : 'heroicons_outline:globe-alt',
        link : '/hackathons'
    },
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    }
];
