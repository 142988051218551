/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();



export const activities = [
    {
        id          : '8f8e1bf9-4661-4939-9e43-390957b60f42',
        icon        : 'heroicons_solid:arrow-path',
        description : 'Project update submitted and recorded on blockchain',
        date        : now.minus({hours: 3}).toISO(), // 3 days ago
        extraContent: `<div>You've submitted an update to your project. This update has been recorded on the blockchain, ensuring the continuous development of your project is documented.</div>`,
    },
    {
        id          : 'fd0f01b4-f3de-4333-add5-cd86850279f8',
        icon        : 'heroicons_solid:arrow-right-on-rectangle',
        description : 'Feedback and improvement suggestions',
        date        : now.minus({hours: 6}).toISO(), // 2 days ago
        extraContent: `<div>Feedback from the judges and suggestions for improvement have been sent to your registered email. We encourage you to review the feedback and continue refining your project.</div>`,
    },
    {
        id          : 'b85c2338-cc98-4140-bbf8-c226ce4e395e',
        icon        : 'heroicons_solid:arrow-path',
        description : 'Blockchain record updated with award details',
        date        : now.minus({days: 2, hours: 2}).toISO(), // 1 day and 2 hours ago
        extraContent: `<div>Your award details have been successfully recorded on the blockchain, ensuring the integrity and proof of your achievement.</div>`,
    },
    {
        id          : 'eb8aa470-635e-461d-88e1-23d9ea2a5665',
        icon        : 'heroicons_solid:trophy',
        description : 'Your project won an award!',
        date        : now.minus({days: 2}).toISO(), // 1 day ago
        extraContent: `<div>Congratulations! Your project has been selected as one of the winners. Check your email for more details on the award and next steps.</div>`,
    },
    {
        id          : 'ef7b95a7-8e8b-4616-9619-130d9533add9',
        icon        : 'heroicons_solid:scale',
        description : 'Final evaluation by judges',
        date        : now.minus({days: 3}).toISO(), // 5 hours ago
        extraContent: `<div>Your submission is now being evaluated by our panel of judges. The final decision will be made soon.</div>`,
    },
    {
        id          : '541416c9-84a7-408a-8d74-27a43c38d797',
        icon        : 'heroicons_solid:adjustments-vertical',
        description : 'Submission passed initial review',
        date        : now.minus({days: 3}).toISO(), // 2 hours ago
        extraContent: `<div>Congratulations! Your submission has passed the initial review phase. It will now undergo further evaluation.</div>`,
    },
    {
        id          : 'b91ccb58-b06c-413b-b389-87010e03a120',
        icon        : 'heroicons_solid:user-group',
        description : 'Admin review started',
        date        : now.minus({days: 3}).toISO(), // 1 hour ago
        extraContent: `<div>Your submission is currently being reviewed by our team of administrators. You will be notified once the review process is completed.</div>`,
    },
    {
        id          : '6e3e97e5-effc-4fb7-b730-52a151f0b641',
        icon        : 'heroicons_solid:check-circle',
        description : 'Blockchain transaction confirmed for your submission',
        date        : now.minus({days: 3}).toISO(), // 30 minutes ago
        extraContent: `<div>Transaction ID: <strong>#78910</strong></div><br>
                        <div>Your submission has been successfully recorded on the blockchain, providing a tamper-proof record of your participation.</div>`,
    },
    {
        id          : '493190c9-5b61-4912-afe5-78c21f1044d7',
        icon        : 'heroicons_solid:arrow-up-tray',
        description : 'Your project submission has been successfully uploaded',
        date        : now.minus({days: 3}).toISO(), // 25 minutes ago
        extraContent: `<div class="font-bold">Submission Uploaded!</div><br>
                        <div>Hi there,<br>Your project submission has been successfully uploaded to the Hack-the-Hackathon platform and recorded on the blockchain. Your submission ID is #123456. You can track the review progress in your dashboard.</div>`,
    },
];
